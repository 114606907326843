import React from "react";
import CommonModal from "../CommonModal";
import * as Yup from "yup";
import './ContactUsModal.scss'

import {
  Label,
  regexChecks,
  validationMessages,
} from "../../../../utils/constants";
import {
  MailIcon,
  PhoneIcon,
  PhoneNewIcon,
} from "../../../../assets/Svg/SvgIcon";
import { Form, Formik } from "formik";
import { Col, Row } from "react-bootstrap";
import Input from "../../Input/Input";
import { contactUs } from "../../../../api/apiHelper";
import { toasts } from "../../Toast/toasts";
import Button from "../../Button/Button";

const ContactUsModal = ({showModal,closeModal}) => {
  const initialValues = {
    name: "",
    mail: "",
    subject: "",
    message: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .matches(regexChecks.full_name, validationMessages.valid_name)
      .min(3, validationMessages.min_words)
      .max(30, validationMessages.max_fullName)
      .required(validationMessages.name),

    mail: Yup.string()
      .matches(regexChecks.email, `${validationMessages.wrong_email}`)
      .max(350, `${validationMessages.email_max}`)
      .required(validationMessages.email_number),
    subject: Yup.string()
      .trim()
      .matches(regexChecks.subject, `${validationMessages.valid_subject}`)
      .min(3, validationMessages.min_words)
      .max(50, validationMessages.max_subject)
      .required(validationMessages.subject),
    message: Yup.string()
      .trim()
      .matches(regexChecks.subject, `${validationMessages.valid_message}`)
      .min(3, validationMessages.min_words)
      .max(500, validationMessages.max_message)
      .required(validationMessages.message),
  });

  const handleSubmit = async (values, actions) => {
    const payload = {
      name: values.name,
      email: values.mail,
      subject: values.subject,
      message: values.message,
    };

    try {
      const response = await contactUs(payload);
      if (response.status === 200) {
        toasts.success(`${response.message}`);
        actions.resetForm();
        closeModal()
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <CommonModal
        show={showModal}
        onHide={closeModal}
        className="contact_us"
        heading="Contact Us"
      >
        <div className="contact_us contact_us_modal">
          <div className="contact_us_data">
            <span className="phone_icon">
              <PhoneIcon />
            </span>
            <div className="contact_us_data_inter">
              <ul>
                <li>
                  <MailIcon />
                  <div className="text-start">
                    <h3>{Label.contact_us.email}</h3>
                    <p>dynga@gmail.com</p>
                  </div>
                </li>
                <li>
                  <PhoneNewIcon />
                  <div className="text-start">
                    <h3>{Label.contact_us.phone}</h3>
                    <p>+1 887 545 1206</p>
                  </div>
                </li>
              </ul>
            </div>
            <div className="contact_us_data_form">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit} 
              >
                {(formik) => (
                  <Form>
                    <Row>
                      <Col lg={12}>
                        <Input
                          label={Label.contact_us.name}
                          placeholder={Label.contact_us.enter_name}
                          control="input"
                          name="name"
                          formik={formik}
                          // validate={validateFullName}
                          style={{
                            textTransform: "capitalize",
                          }}
                        />
                      </Col>
                      <Col lg={12}>
                        <Input
                          label={Label.contact_us.email}
                          placeholder={Label.contact_us.enter_email}
                          control="input"
                          name="mail"
                          formik={formik}
                        />
                      </Col>
                      <Col lg={12}>
                        <Input
                          label={Label.contact_us.subject}
                          placeholder={Label.contact_us.enter_subject}
                          control="input"
                          name="subject"
                          formik={formik}
                        />
                      </Col>
                      <Col lg={12}>
                        <Input
                          label={Label.contact_us.message}
                          placeholder={Label.contact_us.enter_message}
                          control="input"
                          name="message"
                          formik={formik}
                        />
                      </Col>
                      {/* <CommonBtn
                        loader={formik.isSubmitting}
                        title={"Submit"}
                        className="save_btn"
                        type="submit"
                        disabled={
                          !formik.isValid ||
                          !formik.dirty ||
                          formik.isSubmitting
                        }
                      /> */}

                      <Button
                        type="submit"
                        disabled={
                          !formik.isValid ||
                          !formik.dirty ||
                          formik.isSubmitting
                        }
                      >
                        Submit
                      </Button>
                    </Row>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </CommonModal>
    </>
  );
};

export default ContactUsModal;
