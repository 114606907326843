import { ErrorMessage, Field, useField } from "formik";
import React from "react";
import Form from "react-bootstrap/Form";
import TextError from "../TextError/TextError";
import "./Input.scss";
import {
  disableScrollNumberInput,
  handleNumberValues,
} from "../../../utils/helper";

const Input = (props) => {
  const {
    label,
    formik,
    name,
    variant,
    leftIcon,
    labelIconRight,
    labelIcon,
    rightIcon,
    leftIconClick,
    labelIconClick,
    rightIconClick,
    iconClr,
    type,
    sublabel,
    labelRequired,
    handleKeyDown = () => {},
    handleKeyUp = () => {},
    onChange,
    onKeyDown,
    decimalPlaces,
    onCopy,
    onPaste,
    ...rest
  } = props;
  const [field, meta, helpers] = useField(name);
  const handleKeys = (e) => {
    const regex = /^[^<>]*$/;
    !regex.test(e.key) && e.preventDefault();
  };
  const handleArrowKey = (e) => {
    if (e.keyCode === 38 || e.keyCode === 40) {
      e.preventDefault();
    }
  };
  return (
    <Form.Group
      className={`common_input ${variant} ${
        formik.values[name] ? "hasFilled" : ""
      } ${
        formik?.touched[name] && formik?.errors[name]
          ? "common_input--error"
          : ""
      }`}
      controlId={name}
      onKeyDown={() => helpers.setTouched(props?.name)}
    >
      {label && (
        <Form.Label>
          {label}
          {labelRequired && <sup>*</sup>}
          {labelIcon && (
            <span
              onClick={labelIconClick}
              className={`labelIcon ${labelIconClick ? "cursor-pointer" : ""} ${
                labelIconRight ? "ms-auto" : ""
              }`}
            >
              {labelIcon}
            </span>
          )}
          <span className="sublabel">{sublabel}</span>
        </Form.Label>
      )}

      <div
        className={`common_input_inner ${leftIcon ? "leftIconInput" : ""} ${
          rightIcon ? "rightIconInput" : ""
        }`}
      >
        {leftIcon && (
          <span
            className={`${leftIconClick ? "cursor-pointer" : ""} leftIcon`}
            onClick={leftIconClick}
          >
            {leftIcon}
          </span>
        )}
        <Field
          className="form-control"
          type={type ? type : "text"}
          name={name}
          {...rest}
          onWheel={disableScrollNumberInput}
          min={type === "number" && 0}
          step={"any"}
          onKeyDown={(e) => {
            if (type == "number") {
              handleNumberValues(e, decimalPlaces);
              onKeyDown && onKeyDown(e);
              handleArrowKey(e);
            }

            handleKeyDown(e);
            handleKeys(e);
          }}
          onKeyUp={handleKeyUp}
          onPaste={onPaste}
          onCopy={onCopy}
          {...field}
        />
        {rightIcon && (
          <span
            className={`${
              rightIconClick ? "cursor-pointer" : ""
            } rightIcon ${iconClr}`}
            onClick={rightIconClick}
          >
            {rightIcon}
          </span>
        )}
      </div>
      <ErrorMessage name={name} component={TextError} />
    </Form.Group>
  );
};

export default Input;
