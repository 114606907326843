import {
    BitcoinIcon,
    EthIcon,
    TethIcon,
    TronIcon
} from "../../src/assets/Svg/SvgIcon";

export const SOCIAL_MEDIA = {
    INSTAGRAM: "https://www.instagram.com/",
    TWITTER: "https://twitter.com/?lang=en",
    GOOGLE: "https://www.google.com/",
    PINTEREST: "https://in.pinterest.com/login/",
}

export const walletData = [
    {
      picture: <BitcoinIcon />,
      coin: "btc",
      textOne: "Market Price",
      // titleTwo: "0.00",
      marketCap: "$441.26B",
  
      title: "Bitcoin",
      btcValue: "0.00106438 BTC",
      balance: "Available Bal:",
      walletusd: "0.00",
  
      // walletheading: "Bitcoin Wallet",
      btcValue: "0.00106438 BTC",
    },
    {
      picture: <EthIcon />,
      coin: "eth",
      textOne: "Market Price",
      // titleTwo: "0.00",
      marketCap: "$441.26B",
  
      title: "Ethereum",
      btcValue: "0.00106438 BTC",
      balance: "Available Bal:",
      walletusd: "0.00",
  
      // walletheading: "Bitcoin Wallet",
      btcValue: "0.00106438 BTC",
    },
    {
      picture: <TethIcon />,
      coin: "usdt",
      textOne: "Market Price",
      // titleTwo: "0.00",
      marketCap: "$441.26B",
  
      title: "USDT",
      btcValue: "0.00106438 BTC",
      balance: "Available Bal:",
      walletusd: "0.00",
  
      // walletheading: "Bitcoin Wallet",
      btcValue: "0.00106438 BTC",
    },
    {
      picture: <TronIcon />,
      coin: "trx",
      textOne: "Market Cap",
      // titleTwo: "0.00",
      marketCap: "$441.26B",
  
      title: "TRX",
      btcValue: "0.00106438 BTC",
      balance: "Available Bal:",
      walletusd: "0.00",
  
      // walletheading: "KUDI Wallet",
      btcValue: "0.00106438 BTC",
    },
    {
      picture: <TethIcon />,
      coin: "trx-usdt",
      textOne: "Market Price",
      marketCap: "$441.26B",
      title: "TRX-USDT",
      btcValue: "0.00106438 BTC",
      balance: "Available Bal:",
      walletusd: "0.00",
      btcValue: "0.00106438 BTC",
    },
  ];

  export const regexChecks = {
    email: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    full_name: /^[A-Za-z\s]{1,}[\.]{0,1}[A-Za-z\s]{0,}$/,
    subject: /^[a-zA-Z0-9\s\-]/,
  };

  export const validationMessages = {
    "wrong_email": "Please enter a valid email.",
    "email_max": "Email must be at most 350 characters.",
    "email_number": "Please enter your email",
    "min_words": "Please enter minimum 3 characters",
    "max_fullName": "The name must not exceed 50 characters.",
    "max_subject": "The Subject must not exceed 50 characters.",
    "max_message": "The Message must not exceed 500 characters.",
    "name": "Please enter your name.",
    "message": "Please enter your message",
    "subject": "Please enter your subject.",
    "valid_name": "Please enter a valid name.",
    "valid_subject": "Please add valid subject",
    "valid_message": "Please add valid message",
   
  };

export const exceptThisSymbols = ["e", "E", "+", "-"];
export const allowedKeysForNum = [
  "ArrowUp",
  "ArrowDown",
  "ArrowLeft",
  "ArrowRight",
  "Backspace",
  "Delete",
  "Tab",
];

export const Label = {
  "contact_us": {
    "success_message": "Successfully submitted",
    "enter_name": "Enter Your Name",
    "enter_email": "Enter email",
    "enter_subject": "Enter subject",
    "enter_message": "Enter message",
    "subject": "Subject",
    "message": "Message",
    "phone": "Phone",
    "name": "Name",
    "contact_us": "Successfully submitted",
    "email":"Email"
  },
};