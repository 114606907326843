import Modal from "react-bootstrap/Modal";
import "./CommonModal.scss";
const CommonModal = ({ show,
    onHide,
    heading,
    children,
    className,
    headerHide,
    headingContent,
    backdrop,
    keyboard,
    noCross }) => {

    return (
        <Modal
            centered
            show={show}
            onHide={onHide}
            className={`common-modal ${className}`}
            backdrop={backdrop}
            keyboard={keyboard}
        >
            {headerHide ? (
                ""
            ) : (
                <Modal.Header
                    closeButton={!noCross}
                    className={heading ? "" : `${headingContent ? "" : "cross-only"}`}
                >
                    {heading ? <Modal.Title>{heading}</Modal.Title> : headingContent}
                </Modal.Header>
            )}
            <Modal.Body>{children}</Modal.Body>
        </Modal>
    );
};
export default CommonModal;
