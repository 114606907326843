import axios from "axios";
import { decryptionFilter } from "../utils/helper";

const BASE_URL = process.env.REACT_APP_BUYSELL_API_HOST;

const axiosBuySellApi = axios.create({
  baseURL: BASE_URL,
});

function formatUrl(url, params) {
  const fullUrl = `${BASE_URL}/${url}`;
  params =
    params && Object.keys(params).length > 0
      ? `?${new URLSearchParams(params).toString()}`
      : "";
  return `${fullUrl}${params}`;
}

export const apiCallGet = async (url, params = {}, toastOn) => {
  try {
    const formattedUrl = formatUrl(url, params);
    const response = await axiosBuySellApi.get(formattedUrl);
    const decryptData = decryptionFilter(response.data);


    return decryptData;
  } catch (error) {
    const decryptData = decryptionFilter(error?.response?.data || {
      message: error.message || "An error occurred",
      status: error.response?.status || "unknown",
    });


    return decryptData;
  }
};
