import { apiCallPost } from "../axiosApi/axiosApi";
import { apiCallGet } from "../axiosApi/axiosBuySellApi";
import { API_URLS } from "./apiUrls";

export const getMarketCoinAssets = async (data) => {
  return await apiCallGet(API_URLS.getBuySellAssets, data, {});
};
export const contactUs = async (data) => {
  const results = await apiCallPost(API_URLS.contactUs, data, {});
  return results;
};
