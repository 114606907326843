
import * as CryptoJs from 'crypto-js'
import { allowedKeysForNum, exceptThisSymbols } from './constants';
const IS_ENCRYPTION = process.env.REACT_APP_ENCRYPT === "true";
const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY;
const PRIVATE_KEY = process.env.REACT_APP_PRIVATE_KEY;


export const displayValue = (value) => {
    if (value || value == "0") {
      return value;
    } else return "N/A";
  }


  function decodeString(publicKey, privateKey) {
    let decoded = atob(privateKey);
    return decoded;
  }
  
  
  function extractAlphabet() {
    let inputString = decodeString(PUBLIC_KEY, PRIVATE_KEY);
    let inputArr = [0, 1];
    while (inputArr[inputArr.length - 1] < inputString.length) {
      let nextNumber =
        inputArr[inputArr.length - 1] + inputArr[inputArr.length - 2];
      inputArr.push(nextNumber);
    }
  
    let result = "";
    for (let i = 0; i < inputArr.length; i++) {
      let index = inputArr[i] % inputString.length;
      result += inputString.charAt(index);
    }
    let slashIndex = result.indexOf("/");
  
    return result.slice(0, slashIndex);
  }
  
  const key = extractAlphabet();
  
  
  function encryption(payload) {
    try {
      if (payload != undefined || payload != null) {
        const ciphertext = CryptoJs.AES.encrypt(payload, key).toString();
  
        return ciphertext;
      }
    } catch (error) {
      return error;
    }
  }
  
  //decoding
  function decryption(payload) {
    try {
      if (payload) {
        const decryptedText = CryptoJs.AES.decrypt(payload, key);
        const decryptData = decryptedText.toString(CryptoJs.enc.Utf8);
  
        return decryptData;
      }
    } catch (error) {
      console.error(error);
    }
  }
  export const encryptionFilter = (data) => {
    if (!data?.entries && data?.entries === undefined) {
      const encD = IS_ENCRYPTION ? encryption(JSON.stringify(data)) : data;
      return IS_ENCRYPTION ? { reqData: encD } : data;
    } else {
      return data;
    }
  };
  export const decryptionFilter = (data) => {
    if (data && data?.resData && typeof data?.resData == "string") {
      const decD = IS_ENCRYPTION ? JSON.parse(decryption(data?.resData)) : data;
      return IS_ENCRYPTION ? decD : data;
    } else {
      return data;
    }
  };

  export const disableScrollNumberInput = () => {
    document.activeElement.blur();
  };

  export const handleNumberValues = (e, decimalPlaces) => {
    const val = e.target.value;
    const crypto_regex = /^\d*\.?\d{0,7}$/;
    const fiat_regex = /^\d*\.?\d{0,1}$/;
    const isCrypto = decimalPlaces === 8;
    const isFiat = decimalPlaces === 2;
  
    if (!allowedKeysForNum.includes(e.key) && e.key !== "End") {
      if (
        (isCrypto && !crypto_regex.test(val)) ||
        (isFiat && !fiat_regex.test(val))
      ) {
        e.preventDefault();
      } else if (
        !val.includes(".") &&
        (val.length >= 10 || exceptThisSymbols.includes(e.key))
      ) {
        e.preventDefault();
      }
    }
  };