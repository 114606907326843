import React, { useEffect, useState } from "react";
import { Container, Table } from "react-bootstrap";
import btc from "../../../../assets/icons/btc.svg";
import upgraph from "../../../../assets/images/up-graph.svg";
import TitleTxt from "../../../common/TitleTxt/TitleTxt";
import { getMarketCoinAssets } from "../../../../api/apiHelper";
import { DownArrow } from "../../../../assets/icons/icon";
import { walletData } from "../../../../utils/constants";
import { displayValue } from "../../../../utils/helper";
import "./MarketPrices.scss";

const MarketPrices = () => {
  const [coinsDataState, setCoinsDataState] = useState([]);

  const coinsData = {
    change: "2.93%",
    arrow: <DownArrow />,
    graph: upgraph,
    img: btc,
  };

  const getMarketAssetsList = async () => {
    try {
      const response = await getMarketCoinAssets();
      if (response.status === 200) {
        setCoinsDataState(response?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getMarketAssetsList();
  }, []);

  return (
    <section id="market-prices" className="market_prices">
      <Container fluid="lg">
        <TitleTxt title="Market Prices" />
        <div className="table-responsive">
          <Table>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Price</th>
                <th>Change</th>
                <th>Chart</th>
                <th>Trade</th>
              </tr>
            </thead>

            <tbody>
              {coinsDataState?.map((item, index) => {
                const coinsInfo = walletData?.find(
                  (items2) => item?.buyTo?.toLowerCase() === items2?.coin
                );
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                      <div className="coin_detail">
                        {coinsInfo.picture}
                        <div>
                          <h4>{coinsInfo.title}</h4>
                          <p>{coinsInfo.symbol}</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      {`${displayValue(parseFloat(item?.markup))} ${item?.buyFrom
                        }`}
                    </td>
                    <td className="change_txt">
                      {coinsData.arrow} {coinsData.change}
                    </td>
                    <td>
                      <img src={coinsData.graph} alt="graph" />
                    </td>

                    <td>
                      <button
                        className="buy_btn"
                        onClick={() =>
                          window.open(
                            `${process.env.REACT_APP_ROUTE_HOST}/auth/dashboard`
                          )
                        }
                      >
                        Buy
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </Container>
    </section>
  );
};

export default MarketPrices;
