import React from "react";

import "./TextError.scss";
const TextError = (props) => {
  return (
    <div
      className={props && props.Checkbox ? "checkbox_error" : "error_message"}
    >
      {props.children}
    </div>
  );
};

export default TextError;
