import axios from "axios";
import { decryptionFilter, encryptionFilter } from "../utils/helper";

const BASE_URL = process.env.REACT_APP_API_HOST;

const axiosApi = axios.create({
  baseURL: BASE_URL,
});

function formatUrl(url, params) {
  const fullUrl = `${BASE_URL}/${url}`;
  params =
    params && Object.keys(params).length > 0
      ? `?${new URLSearchParams(params).toString()}`
      : "";
  return `${fullUrl}${params}`;
}


export const apiCallPost = async (url, data, params = {}, toastOn, header) => {
  const dataFiltered = encryptionFilter(data);
  try {
    const resp = await axiosApi.post(
      formatUrl(url, params),
      dataFiltered,
      {},
      header
    );
    const decryptData = decryptionFilter(resp?.data);
    return decryptData;
  } catch (error) {
    const decryptData = decryptionFilter(error?.response?.data);
    return decryptData;
  }
};