import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-scroll";
import google from "../../../assets/icons/google.svg";
import ig from "../../../assets/icons/ig.svg";
import pinterest from "../../../assets/icons/pinterest.svg";
import twitter from "../../../assets/icons/twitter.svg";
import logo from "../../../assets/logo/logo.svg";
import { SOCIAL_MEDIA } from "../../../utils/constants";
import Button from "../Button/Button";
import "./Footer.scss";
import ContactUsModal from "../Modal/ContactUsModal/ContactUsModal";

const Footer = () => {
  const [showContactUsModal,setShowContactUsModal] = useState(false)
  return (
    <footer className="footer">
      <Container>
        <div className="footer_in">
          <div className="footer_content">
            <Link
              className="logo"
              to="banner"
              spy={true}
              smooth={true}
              duration={500}
            >
              <img src={logo} alt="logo" />
            </Link>
          </div>
          <div className="footer_nav">
            <div className="footer_nav_list">
              <h3>Company</h3>
              <ul>
                <li>
                  <Link
                    activeClass="active"
                    to={"banner"}
                    spy={true}
                    smooth={true}
                    duration={500}
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    activeClass="active"
                    to={`${process.env.REACT_APP_ROUTE_HOST}/login`}
                    spy={true}
                    smooth={true}
                    duration={500}
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_ROUTE_HOST}/login`,
                        "_blank"
                      )
                    }
                  >
                    Buy Crypto
                  </Link>
                </li>
                <li>
                  <Link
                    activeClass="active"
                    to={"features"}
                    spy={true}
                    smooth={true}
                    duration={500}
                  >
                    Features
                  </Link>
                </li>
                <li >
                  <Link
                    activeClass="active"
                    to={"trade"}
                    spy={true}
                    smooth={true}
                    duration={500}
                    onClick={()=>setShowContactUsModal(true)}
                  >
                    Contact US
                  </Link>
                </li>
              </ul>
            </div>
            {/* <div className="footer_nav_list">
              <h3>Explore</h3>
              <ul>
                <li>
                  <a href="/" target="_blank" rel="noreferrer">
                    Collections
                  </a>
                </li>
                <li>
                  <a href="/" target="_blank" rel="noreferrer">
                    NFTs
                  </a>
                </li>
              </ul>
            </div> */}
          </div>
          <div className="stay_touch">
            <h3>Stay In Touch</h3>
            <p className="footer_txt">
              Lorem ipsum dolor sit amet consectetur <br />
              adipiscing elit luctus cursus.
            </p>
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="input">
                <input type="email" placeholder="info@dynga.com" />
                <Button
                  type="submit"
                  onClick={() =>
                    window.open(`${process.env.REACT_APP_ROUTE_HOST}/register`)
                  }
                >
                  Sign Up
                </Button>
              </div>
            </form>
            <ul>
              <li>
                <a
                  href={SOCIAL_MEDIA.INSTAGRAM}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={ig} alt="instagram" />
                </a>
              </li>
              <li>
                <a href={SOCIAL_MEDIA.TWITTER} target="_blank" rel="noreferrer">
                  <img src={twitter} alt="twitter" />
                </a>
              </li>
              <li>
                <a href={SOCIAL_MEDIA.GOOGLE} target="_blank" rel="noreferrer">
                  <img src={google} alt="google" />
                </a>
              </li>
              <li>
                <a
                  href={SOCIAL_MEDIA.PINTEREST}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={pinterest} alt="pinterest" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </Container>
      <div className="footer_end">
        <Container>
          <div className="footer_end_in">
            <p>
              © Copyright {new Date().getFullYear()} Dynga Company Limited. All
              Rights Reserved.
            </p>
            <ul>
              <li>
                <a
                  href={`${process.env.REACT_APP_ROUTE_HOST}/privacy-policy`}
                  rel="noreferrer"
                  target="_blank"
                >
                  Privacy Policy
                </a>
              </li>
              <li>
                <a
                  href={`${process.env.REACT_APP_ROUTE_HOST}/t&c`}
                  rel="noreferrer"
                  target="_blank"
                >
                  Terms of Service
                </a>
              </li>
            </ul>
          </div>
        </Container>
      </div>
      {showContactUsModal &&  <ContactUsModal showModal={showContactUsModal} closeModal={()=>setShowContactUsModal(false)}/>}
    </footer>
  );
};

export default Footer;
